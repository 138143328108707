import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, observable } from 'rxjs';
import { API } from 'src/app/consts/Api';
import { StorageKeys } from '../consts/storage-keys';
import { HttpService } from '../services/http/http.service';
import { UserService } from '../services/user/user.service';
import { UtilityService } from '../services/utility/utility.service';

@Injectable({
  providedIn: 'root',
})
export default class UserDataResolver  {
  constructor(
    public userService: UserService,
    private http: HttpService,
    private utilityService: UtilityService
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.utilityService.getSessionStorage(StorageKeys.UserToken)) {
      return;
    } else {
      if (this.userService.userData) {
        return this.userService.userData;
      } else {
        const obs = new Observable((observer) => {
          this.http.get(API.GET_DETAILS).subscribe({
            next: (res: any) => {
              this.userService.isLogIn = true;
              this.userService.userData = res;
              this.userService.onUserLoginStatusChange.emit(res);
              observer.next(res);
            },
            error: (err: any) => {
              observer.error(err);
            },
          });
        });
        return obs;
      }
    }
  }
}
