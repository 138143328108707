import { Routes } from '@angular/router';
import { AuthGuardService } from '../services/guard/auth-guard.service';
import UserDataResolver from '../resolvers/user-data.resolver';

export const PagesRoutingModule: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        data: { breadcrumb: 'Home' },
        loadChildren: () =>
          import('./general/general.module').then((x) => x.GeneralModule),
      },
    ],
    resolve: {
      userData: UserDataResolver,
    },
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./forum/forum.module').then((x) => x.ForumModule),
      },
    ],
    // data: { breadcrumb: 'Home' },
    // data: { breadcrumb: { skip: true } },
    //   canActivate: [AuthGuardService],
    resolve: {
      userData: UserDataResolver,
    },
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./supplier/supplier.module').then((x) => x.SupplierModule),
      },
    ],
    // data: { breadcrumb: 'Home' },
    // data: { breadcrumb: { skip: true } },
    resolve: {
      userData: UserDataResolver,
    },
  },
];
