import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { ComponentsModule } from '../components/components.module';

@NgModule({
	declarations: [PagesComponent],
	imports: [
		CommonModule,
		RouterModule.forChild(PagesRoutingModule),
		ComponentsModule,
	],
})
export class PagesModule { }
