import { Component } from '@angular/core';
import { HttpService } from '../services/http/http.service';
import { API } from '../consts/Api';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
})
export class PagesComponent {
  brands: any = [];
  constructor(private http: HttpService) {}

  ngOnInit(): void {
    this.getBrands();
  }

  getBrands() {
    this.http.get(API.GET_BRANDS).subscribe({
      next: (res: any) => {
        this.brands = res;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
